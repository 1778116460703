<template>
  <v-hover>
    <template v-slot:default="{ hover }">
      <v-card
        class="mx-auto"
        max-width="400"
      >
        <v-img
          v-if="!image"
          src="https://cdn.vuetifyjs.com/images/cards/forest-art.jpg"
          max-height="225"
          max-width="400"
        ></v-img>
        <v-img
          v-if="image"
          :src="'/images/products/'+image"
          max-height="225"
          max-width="400"
        ></v-img>
        <v-card-text>
          <h2 class="title primary--text">
            {{ title }}
          </h2>
        </v-card-text>

        <v-fade-transition>
          <v-overlay
            v-if="hover"
            absolute
            color="#595959"
            opacity=".8"
          >
            <ul>
              <li
                v-for="(line, j) in lines"
                :key="j"
              >
                {{ line.text }}
              </li>
            </ul>
          </v-overlay>
        </v-fade-transition>
        <v-dialog
          v-model="dialog"
        >
          <v-card>
            <v-card-title
              class="headline grey lighten-2"
              primary-title
            >
              {{ title }}
              <v-btn
                color="primary"
                text
                style="float:right;"
                @click="dialog = false;"
              >
                X
              </v-btn>
            </v-card-title>

            <v-card-text>
              <v-row dense>
                <v-col :cols="image?8:12">
                  <div v-html="desc"></div>
                </v-col>
                <v-col
                  v-if="image"
                  cols="4"
                >
                  <v-img
                    v-if="image"
                    :src="'/images/services/'+image"
                    max-height="225"
                    max-width="400"
                  ></v-img>
                </v-col>
              </v-row>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                text
                @click="dialog = false"
              >
                Close
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card>
    </template>
  </v-hover>
</template>

<script>
  export default {
    name: 'Product',
    props: ['title', 'desc', 'image', 'lines'],
    data: function () {
      return {
        dialog: false,
      }
    },
    mounted () {
      this.initialize()
    },
    methods: {
      initialize () {
      },
    },
  }
</script>
